import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaWhatsapp, FaYoutube } from 'react-icons/fa';

import { Container, Content, FooterSpan, IconButton, LinksContainer, LogoStar, SpanInvestidor } from './styles';
import Logo7Star from '../../assets/images/logo-7star.svg'
import { getTypeParameterOwner } from 'typescript';

const Footer: React.FC = () => {

  return (
    <Container>
      <Content>
        <FooterSpan>© {new Date().getFullYear()}. Cloudmed Tecnologia</FooterSpan>
        <SpanInvestidor>
          <FooterSpan>Uma empresa investida por:</FooterSpan>
            <LogoStar src={Logo7Star}/>
        </SpanInvestidor>
        <LinksContainer>
          <IconButton href="https://www.linkedin.com/company/cloudmed" hoverColor="#45abff">
            <FaLinkedinIn/>
          </IconButton>
          <IconButton href="https://www.instagram.com/cloudmedbr" hoverColor="#8f6ec7">
            <FaInstagram/>
          </IconButton>
          <IconButton href="https://www.facebook.com/cloudmedtecnologia" hoverColor="#4f62cf">
            <FaFacebookF/>
          </IconButton>
          <IconButton href="https://www.youtube.com/user/canalcloudmed" hoverColor="#ff6055">
            <FaYoutube/>
          </IconButton>
          <IconButton href="https://wa.me/5516999883165" hoverColor="#5aca5e">
            <FaWhatsapp/>
          </IconButton>
        </LinksContainer>
      </Content>
    </Container>
  );
};

export default Footer;
