import QrCode from '../../../assets/images/qrCode.jpg';
import Acreditamos from '../../../assets/images/acreditamos-gif.svg';

export const Thecode = [
  {
    id: 1,
    title: '<the code/>',
    text: 'Contribuímos com o crescimento das pessoas. Somos fãs de atitudes positivas. Cordialidade, Simpatia e Empatia, sempre. Talentos atraem talentos. Pensamos simples: menos é mais. Descartamos o uniforme mas amamos vestir a camisa do time. O sucesso de nosso cliente é nosso sucesso. Acreditamos no que construímos. Prezamos pelas famílias. Exaltamos o voluntariado, a doação de si em causa de outro.',
    image: `url(${QrCode})`
  },
  {
    id: 2,
    title: 'No que acreditamos',
    text: 'Acreditamos que as coisas podem ser feitas de formas diferentes e inovadoras, por isso estamos sempre inconformados com as mesmices impostas por regras que impedem o setor de crescer e se reinventar. Somos inconformados com processos que geram desperdícios e atrasam o avanço de nossa geração, bem como com o desrespeito às pessoas.',
    image: `url(${Acreditamos})`
  },
];
