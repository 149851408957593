import React, { useState } from 'react';
import {FaTimesCircle} from 'react-icons/fa';

import {
  AboutSpan,
  CardContent,
  CloseButton,
  Container,
  Content,
  Details,
  ImageContainer,
  TextContainer,
  Wrapper
} from './styles';
import Card from '../../components/Card';
import PageDescription from '../../components/PageDescription';
import Title from '../../components/Title';
import {ObjectModel } from './utils/models/ObjectModel';
import { Thecode } from './utils/texts';
import ContainerProps from '../../models/container';
import Acreditamos from '../../assets/images/acreditamos-gif.svg'
import Team from '../../assets/images/team.svg'
import { textSpanIntersection } from 'typescript';

const TheCode: React.FC<ContainerProps> = ({forwardRef}) => {
  const defaultObject = {id: 0, title: '', text: '', image: ''};
  const [object, setObject] = useState<ObjectModel>(defaultObject)


  return (
    <Container ref={forwardRef}>
      <Content>
        <Title text="<THE CODE/>"/>
        <PageDescription text="Aqui valorizamos o seu crescimento profissional e pessoal.
        Temos um código que traduz claramente nossa cultura."/>
        <Details show={object.text.length > 0}>
          <div>
            <ImageContainer>
              <div style={{backgroundImage: object.image}}/>
            </ImageContainer>
            <TextContainer>
              <h1>{object.title}</h1>
              <AboutSpan>
                {object.text}
              </AboutSpan>
            </TextContainer>
          </div>
        <CloseButton onClick={() => setObject(defaultObject)}>
          <FaTimesCircle color="#f9B43A" fontSize="28"/>
        </CloseButton>
        </Details>
        <CardContent>
            <Wrapper>
              <Card
                text="The Code"
                img={Team}
                onPress={() => setObject(Thecode[0])}
                cardId={1}
                objectId={object.id}
              />
              <Card
                text="No que acreditamos"
                img={Acreditamos}
                onPress={() => setObject(Thecode[1])}
                cardId={2}
                objectId={object.id}
              />
            </Wrapper>
          </CardContent>
      </Content>
    </Container>
  );
};

export default TheCode;
