import styled from 'styled-components';

export const Text = styled.span`
    font-size: 18px;
    color:#EDF2F7;
    margin-top: 10px;
    width:90%;
    max-width: 500px;
    text-align:center;
`;

